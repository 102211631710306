import { defineStore } from 'pinia';
import { ref } from 'vue';

export interface GoodsLog {
  keyword: string;
  createdAt: string;
  item: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  premiumInfo?: {
    translateName: string;
  };
}

export const useGoodsStore = defineStore('goods', () => {
  const goodsLogs = ref<GoodsLog[]>([]);

  const refreshGoodsLog = async () => {
    goodsLogs.value = await askHttp({
      path: '/goods-log/me',
      method: 'get',
    });
  };

  const fetchLatestGoodsLog = async () => {
    const nowGoodsLogLatestDate = goodsLogs.value[0]?.createdAt;
    const newItems = nowGoodsLogLatestDate
      ? await askHttp({
          path: `/goods-log/me?after=${
            goodsLogs.value.length > 0 ? goodsLogs.value[0].createdAt : ''
          }`,
          method: 'get',
        })
      : await askHttp({
          path: '/goods-log/me',
          method: 'get',
        });

    if (newItems.length > 0) {
      newItems.filter((item: GoodsLog) => {
        return item.createdAt > nowGoodsLogLatestDate;
      });
      goodsLogs.value = [...newItems, ...goodsLogs.value];
    }
  };

  return {
    goodsLogs,
    refreshGoodsLog,
    fetchLatestGoodsLog,
  };
});
