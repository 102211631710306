export const useEnv = () => {
  const runtime = useRuntimeConfig();
  const isDev = () => {
    return runtime.public.ENV === 'development';
  };
  return {
    isDev,
    ...runtime.public,
  };
};
