import { default as chargeueIkZSW3XCMeta } from "/home/nes/Desktop/swaping-danger-meoww/pages/dashboard/charge.vue?macro=true";
import { default as debugpds1L1sIQHMeta } from "/home/nes/Desktop/swaping-danger-meoww/pages/dashboard/debug.vue?macro=true";
import { default as goodst0IxA01cO1Meta } from "/home/nes/Desktop/swaping-danger-meoww/pages/dashboard/goods.vue?macro=true";
import { default as index0Hjr4AXYSKMeta } from "/home/nes/Desktop/swaping-danger-meoww/pages/dashboard/index.vue?macro=true";
import { default as subscription2gPkXlfGZUMeta } from "/home/nes/Desktop/swaping-danger-meoww/pages/dashboard/subscription.vue?macro=true";
import { default as _91id_93NB16emz2WnMeta } from "/home/nes/Desktop/swaping-danger-meoww/pages/goods/[id].vue?macro=true";
import { default as indexiF6SOBIFXbMeta } from "/home/nes/Desktop/swaping-danger-meoww/pages/index.vue?macro=true";
import { default as indexirHYDtWl5SMeta } from "/home/nes/Desktop/swaping-danger-meoww/pages/login/index.vue?macro=true";
export default [
  {
    name: "dashboard-charge",
    path: "/dashboard/charge",
    component: () => import("/home/nes/Desktop/swaping-danger-meoww/pages/dashboard/charge.vue")
  },
  {
    name: "dashboard-debug",
    path: "/dashboard/debug",
    component: () => import("/home/nes/Desktop/swaping-danger-meoww/pages/dashboard/debug.vue")
  },
  {
    name: "dashboard-goods",
    path: "/dashboard/goods",
    component: () => import("/home/nes/Desktop/swaping-danger-meoww/pages/dashboard/goods.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/home/nes/Desktop/swaping-danger-meoww/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-subscription",
    path: "/dashboard/subscription",
    component: () => import("/home/nes/Desktop/swaping-danger-meoww/pages/dashboard/subscription.vue")
  },
  {
    name: "goods-id",
    path: "/goods/:id()",
    component: () => import("/home/nes/Desktop/swaping-danger-meoww/pages/goods/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/nes/Desktop/swaping-danger-meoww/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/nes/Desktop/swaping-danger-meoww/pages/login/index.vue")
  }
]