<script setup lang="ts">
import { useIntervalFn } from '@vueuse/core';
import { useGoodsStore } from './stores/goods';

const goodsStore = useGoodsStore();
const userStore = useUserStore();

useHead({
  title: '次元喵屋',
  meta: [
    {
      name: 'description',
      content: '属于吃谷人的抱团取暖小窝',
    },
  ],
});

const layout = computed(() => {
  return userStore.isAuthenticated() ? 'dashboard' : 'auth';
});

onMounted(() => {
  useIntervalFn(() => {
    if (!userStore.isAuthenticated()) {
      return;
    }
    goodsStore.fetchLatestGoodsLog();
  }, 1000);
});
</script>

<template>
  <NuxtLayout :name="layout">
    <!-- <PwaInfo /> -->
    <!-- <PwaManifestProvider /> -->

    <BackgroundPattern />
    <NuxtPage />
    <UNotifications />
  </NuxtLayout>
</template>

<style lang="scss" scoped>
.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  transform: translateY(8px);
}
</style>
