import revive_payload_client_NJdGCiT6fV from "/home/nes/Desktop/swaping-danger-meoww/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@2.79.2_sass@1.82.0_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EDWKVVgTTy from "/home/nes/Desktop/swaping-danger-meoww/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@2.79.2_sass@1.82.0_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jrIoabtbBu from "/home/nes/Desktop/swaping-danger-meoww/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@2.79.2_sass@1.82.0_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_9xYoYmTIru from "/home/nes/Desktop/swaping-danger-meoww/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@2.79.2_sass@1.82.0_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_SG0lEYhpC9 from "/home/nes/Desktop/swaping-danger-meoww/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@2.79.2_sass@1.82.0_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_mB5aD8Yv6m from "/home/nes/Desktop/swaping-danger-meoww/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@2.79.2_sass@1.82.0_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_g3urZjjPTh from "/home/nes/Desktop/swaping-danger-meoww/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@2.79.2_sass@1.82.0_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_fHJS2pKXjJ from "/home/nes/Desktop/swaping-danger-meoww/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@2.79.2_typescript@5.7.2_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/nes/Desktop/swaping-danger-meoww/.nuxt/components.plugin.mjs";
import prefetch_client_bRgA5eH5ya from "/home/nes/Desktop/swaping-danger-meoww/node_modules/.pnpm/nuxt@3.14.1592_eslint@9.16.0_rollup@2.79.2_sass@1.82.0_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_cqBv4xLhU5 from "/home/nes/Desktop/swaping-danger-meoww/node_modules/.pnpm/pinia-plugin-persistedstate@4.1.3_@pinia+nuxt@0.5.5_pinia@2.3.0_rollup@2.79.2/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import slideovers_9vMg1hVva6 from "/home/nes/Desktop/swaping-danger-meoww/node_modules/.pnpm/@nuxt+ui@2.20.0_axios@1.7.9_qrcode@1.5.4_rollup@2.79.2_typescript@5.7.2_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_BGz4ppTXq4 from "/home/nes/Desktop/swaping-danger-meoww/node_modules/.pnpm/@nuxt+ui@2.20.0_axios@1.7.9_qrcode@1.5.4_rollup@2.79.2_typescript@5.7.2_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_48758hYhUo from "/home/nes/Desktop/swaping-danger-meoww/node_modules/.pnpm/@nuxt+ui@2.20.0_axios@1.7.9_qrcode@1.5.4_rollup@2.79.2_typescript@5.7.2_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_BgDihNaN7u from "/home/nes/Desktop/swaping-danger-meoww/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_rollup@2.79.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_yOQlMNfldN from "/home/nes/Desktop/swaping-danger-meoww/node_modules/.pnpm/@nuxt+icon@1.9.1_rollup@2.79.2_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/nes/Desktop/swaping-danger-meoww/.nuxt/pwa-icons-plugin.ts";
import pwa_client_VoC7u5M2wb from "/home/nes/Desktop/swaping-danger-meoww/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_rollup@2.79.2_vite@6.0.3_workbox-build@7.3.0_workbox-window@7.3.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
export default [
  revive_payload_client_NJdGCiT6fV,
  unhead_EDWKVVgTTy,
  router_jrIoabtbBu,
  payload_client_9xYoYmTIru,
  navigation_repaint_client_SG0lEYhpC9,
  check_outdated_build_client_mB5aD8Yv6m,
  chunk_reload_client_g3urZjjPTh,
  plugin_vue3_fHJS2pKXjJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bRgA5eH5ya,
  plugin_cqBv4xLhU5,
  slideovers_9vMg1hVva6,
  modals_BGz4ppTXq4,
  colors_48758hYhUo,
  plugin_client_BgDihNaN7u,
  plugin_yOQlMNfldN,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_VoC7u5M2wb
]