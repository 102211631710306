<script setup lang="ts">
const colorMode = useColorMode();

const isDark = computed({
  get() {
    return colorMode.value === 'dark';
  },
});
</script>

<template>
  <div v-if="isDark" class="pattern-dark scale-outin">
    <div class="gradient-mask-dark backdrop-blur-md" />
  </div>
  <div v-else class="pattern scale-outin">
    <div class="gradient-mask backdrop-blur-md" />
  </div>
</template>

<style lang="scss" scoped>
.scale-outin {
  animation: scale-outin-animate 0.3s ease infinite;
}

@keyframes scale-outin-animate {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 1;
  }
}

.pattern {
  @apply absolute overflow-hidden left-0 top-0 z-[-2];

  background-color: #f7f4e5;
  background-image: repeating-radial-gradient(
      circle at 40% 30%,
      transparent 0,
      #f7f4e5 120px
    ),
    repeating-linear-gradient(#f79544, #f7be44cf);
}

.pattern-dark {
  @apply absolute overflow-hidden left-0 top-0 z-[-2];

  background-color: #1a202c;
  background-image: repeating-radial-gradient(
      circle at 40% 30%,
      transparent 0,
      #2c261a 120px
    ),
    repeating-linear-gradient(#67652782, #9262319e);
}

.gradient-mask-dark {
  @apply absolute inset-0 z-[-1];
  background-image: linear-gradient(
    -15deg,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.75) 20%,
    black 50%,
    black
  );
}

.gradient-mask {
  @apply absolute inset-0 z-[-1];
  background-image: linear-gradient(
    -15deg,
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.95) 20%,
    white 50%,
    white
  );
}
</style>
