import axios from 'axios';

export type Method = 'get' | 'post' | 'put' | 'delete';

export interface HttpOptions {
  method: Method;
  path: string;
  withoutToken?: boolean;
  data?: Record<string, unknown>;
}

export const askHttp = async (options: HttpOptions) => {
  const tk = useUserStore().token;
  const auth = tk ? `Bearer ${tk}` : undefined;

  const apiURL = useEnv().API_URL;

  const config = {
    method: options.method,
    url: `${apiURL}${options.path}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: !options.withoutToken && auth ? auth : undefined,
    },
    data: options.data,
  };

  const response = await axios(config);
  if (response.data?.statusCode === 401) {
    useUserStore().logout();
  }
  return response.data;
};
