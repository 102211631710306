import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { SubscriptionLevel } from '~/components/dashboard/subscriptions/mapping';

export interface LoginCredentials {
  username: string;
  password: string;
}

export interface IUser {
  id: string;
  username: string;
  balance: number;
  email: string;
  group: boolean;
  subscriptions: ISubscription[];
  channel: IChannel;
}

export interface IChannel {
  wxpusher_uid: string;
}

export interface ISubscription {
  id: number;
  startDate: string;
  endDate: string;
  level: SubscriptionLevel;
  fetchInfo: { keyword: string };
}

export const useUserStore = defineStore(
  'user',
  () => {
    const user = ref<IUser | null>(null);
    const token = ref('');

    const login = async (loginDto: LoginCredentials) => {
      const { accessToken } = await askHttp({
        method: 'post',
        path: '/users/login',
        data: { ...loginDto },
      });
      if (!accessToken) {
        throw new Error('Invalid token');
      }
      token.value = accessToken;
      return refreshUserProfile();
    };

    const logout = () => {
      token.value = '';
      user.value = null;
      navigateTo('/login?logout=true');
    };

    const isAuthenticated = () => {
      return !!token.value;
    };

    const refreshUserProfile = async () => {
      const res = await askHttp({
        method: 'get',
        path: '/users/me',
      });
      if (!res?.id) {
        return null;
      }
      user.value = res;
      return res;
    };

    return {
      user,
      token,
      login,
      logout,
      isAuthenticated,
      refreshUserProfile,
    };
  },
  {
    persist: true,
  }
);
